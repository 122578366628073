import { Button, useMediaQuery } from '@mui/material'
import { MobileFooter } from 'Components/panes/mobileFooter'
import { useMemo } from 'react'
import { Breakpoint } from 'react-socks'
import { browserState } from 'store/browserState'
import { useGames } from 'store/game/gameContext'
import { useGameFilter } from 'store/game/gameFilterContext'
import { useRounds } from 'store/game/roundContext'
import { LeaderboardsProvider } from 'store/leaderboard/leaderboardContext'
import { useAuth } from 'store/user/authContext'
import { query } from 'styles/mediaQueries'
import { findRealFromIds } from 'util/list'
import { UserPanelView } from './userPanelView'

export const UserPanel = ({ closeDrawer }) => {
  const { user, signOut } = useAuth()
  const { gameLimit } = useGameFilter()

  const { availableGames, selectedGames, selectedGamesOutsideRound } =
    useGames()
  const { mainRounds, selectedRound } = useRounds()
  const currentRound = mainRounds.current
  const isCurrentRound = selectedRound.id === currentRound.id

  // Convert to real game data
  const gameList = useMemo(() => {
    const gameListIds = selectedGames?.map((game) => {
      return game.id
    })
    return findRealFromIds(availableGames, gameListIds)
  }, [availableGames, selectedGames])

  const gamesOutsideList = useMemo(() => {
    let gamesOutsideList = []
    if (isCurrentRound) {
      gamesOutsideList = selectedGamesOutsideRound
    }
    const gamesOutsideListIds = gamesOutsideList?.map((game) => {
      return game.id
    })
    return findRealFromIds(availableGames, gamesOutsideListIds)
  }, [availableGames, selectedGamesOutsideRound, isCurrentRound])

  const isLoginProviderGoogle = browserState.getLoginProvider() === 'GOOGLE'

  const isMobile = useMediaQuery(query.mobile)

  return (
    <>
      <div
        style={{
          textAlign: 'right',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginTop: '-48px',
        }}
      >
        {!isLoginProviderGoogle && !isMobile && (
          <Button variant="outlined" onClick={signOut}>
            Sign out
          </Button>
        )}
      </div>
      <div style={{ marginTop: '32px' }}>
        <LeaderboardsProvider>
          <UserPanelView
            user={user}
            gameList={gameList}
            gamesOutsideList={gamesOutsideList}
            gameLimit={gameLimit}
            closeDrawer={closeDrawer}
          />
        </LeaderboardsProvider>
      </div>
      <Breakpoint customQuery={query.tabletDown}>
        <MobileFooter />
      </Breakpoint>
    </>
  )
}
