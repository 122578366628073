import { Box } from '@mui/material'
import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { AboutUs } from './aboutUs'
import { Contact } from './contact'

export const MobileFooter = () => {
  return (
    <ChildSpacerV>
      <Box p={2} />
      <Contact />
      <Box p={2} />
      <AboutUs />
    </ChildSpacerV>
  )
}
