import { GameFilterView } from 'Components/game/gameFilterView'
import { SubtleText } from 'Components/visual/SubtleText'
import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
export const GamesContent = () => {
  return (
    <>
      <h1>Games</h1>
      <Box p={2}></Box>
      <Outlet />
      <GameFilterView />
      <Box p={1}></Box>
      <SubtleText text="Currently only Xbox One/S/X, PS4/5 and Nintendo Switch games are included in the game."></SubtleText>
      <Box p={2}></Box>
    </>
  )
}
