import { analyticsLogEvent } from 'Components/firebase'
import produce from 'immer'
import React from 'react'
import { useAuth } from 'store/user/authContext'
import { usePredictions } from './predictionsContext'

const UserPredictGameContext = React.createContext()

function PredictGameProvider({ ...props }) {
  const { children } = props
  const { user, setUser } = useAuth()
  const { roundGamePredictions, updateRoundGamePrediction } = usePredictions()

  const onPredictGame = (newPrediction) => {
    const predictedGameId = newPrediction.gameId

    // Update game with predictions
    const gamePredictionsList = roundGamePredictions.filter(
      (game) => game.id === predictedGameId
    )

    let updatedGamePrediction = null
    if (gamePredictionsList.length > 0) {
      const gamePredictions = gamePredictionsList[0]

      if (newPrediction.score === 0) {
        updatedGamePrediction = produce(gamePredictions, (draft) => {
          if (!gamePredictions.predictions) {
            gamePredictions.predictions = { teams: [] }
          }

          const teams = gamePredictions.predictions.teams
          const teamsWOTeam = teams.filter(
            (team) => team.id !== newPrediction.teamId
          )
          draft.predictions.teams = teamsWOTeam
        })
      } else {
        updatedGamePrediction = produce(gamePredictions, (draft) => {
          if (!gamePredictions.predictions) {
            gamePredictions.predictions = { teams: [] }
          }

          const teams = gamePredictions.predictions.teams
          const teamsWOTeam = teams.filter(
            (team) => team.id !== newPrediction.teamId
          )
          draft.predictions.teams = [
            ...teamsWOTeam,
            {
              id: newPrediction.teamId,
              name: user.team.name,
              score: newPrediction.score,
              wins: user.team.wins,
            },
          ]
        })
      }

      // Update roundGamePrediction with new prediction
      updateRoundGamePrediction(updatedGamePrediction)
    }

    // Update user with prediction saved to backend
    const updatedUser = produce(user, (draft) => {
      if (!draft.team.predictions) {
        draft.team.predictions = {}
      }

      draft.team.predictions[predictedGameId] =
        newPrediction.score === 0 ? undefined : newPrediction.score
    })

    setUser(updatedUser)

    analyticsLogEvent('manage_games', { name: 'predict_game' })
  }

  const predictGameContext = {
    onPredictGame,
  }

  return (
    <UserPredictGameContext.Provider value={predictGameContext}>
      {children}
    </UserPredictGameContext.Provider>
  )
}

function usePredictGame() {
  const context = React.useContext(UserPredictGameContext)
  if (!context) {
    throw new Error(`usePickGame must be used within a PickGameProvider`)
  }
  return context
}

export { PredictGameProvider, usePredictGame }
