import { CssBaseline } from '@mui/material'
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles'
import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './util/reportWebVitals'

import '@fontsource/manrope'
import { UINotifSnackbar } from 'Components/visual/uiNotifSnackbar'
import { GamesProvider } from 'store/game/gameContext'
import { GameFilterContextProvider } from 'store/game/gameFilterContext'
import { PredictionsProvider } from 'store/game/predictionsContext'
import { RoundsProvider } from 'store/game/roundContext'
import { UINotifsProvider } from 'store/game/uiNotificationsContext'
import { PickGameProvider } from 'store/game/userPickGameContext'
import { AuthContextProvider } from 'store/user/authContext'
import { CacheContextProvider } from 'util/cacheContext'
import {
  buildCurrentRound,
  buildNextRound,
  buildPreviousRound,
} from 'util/round'
import App from './App'
import { colors } from './styles/colors'
import { PredictGameProvider } from 'store/game/userPredictGameContext'
import { WatchlistProvider } from 'store/game/watchlistContext'
import { ErrorBoundary } from 'react-error-boundary'

let theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          height: '40px',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: 'none',
          backgroundColor: colors.body,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          maxWidth: '500px',
          width: '80%',
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: colors.mint,
    },
    /*     secondary: {
      main: colors.mint,
    },
 */
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    fontFamily: ['Manrope', 'Arial', 'sans-serif'].join(','),
  },
})

theme = responsiveFontSizes(theme)

const currentRound = buildCurrentRound()
const mainRounds = {
  current: currentRound,
  next: buildNextRound(currentRound),
  prev: buildPreviousRound(currentRound),
}

const logError = (error, info) => {
  // Do something with the error, e.g. log to an external API
  console.error(error, info)
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ErrorBoundary
      fallback={
        <div>
          Something went wrong. Leave feedback on Discord, preferably with
          screenshots or any errors from your browser console.
        </div>
      }
      onError={logError}
    >
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <CacheContextProvider>
            <RoundsProvider mainRounds={mainRounds}>
              <PredictionsProvider>
                <AuthContextProvider>
                  <GamesProvider>
                    <GameFilterContextProvider>
                      <UINotifsProvider>
                        <PickGameProvider>
                          <WatchlistProvider>
                            <PredictGameProvider>
                              <UINotifSnackbar />
                              <App />
                            </PredictGameProvider>
                          </WatchlistProvider>
                        </PickGameProvider>
                      </UINotifsProvider>
                    </GameFilterContextProvider>
                  </GamesProvider>
                </AuthContextProvider>
              </PredictionsProvider>
            </RoundsProvider>
          </CacheContextProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </ErrorBoundary>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
