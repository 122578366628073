import { Button } from '@mui/material'
import { colors } from 'styles/colors'
export const ListButton = ({ onClick, icon, label }) => {
  return (
    <Button
      onClick={onClick}
      style={{
        border: `1px solid ${colors.mint}`,
        borderRadius: '4px',
      }}
    >
      <span className="material-symbols-outlined">{icon}</span> {label}
    </Button>
  )
}
