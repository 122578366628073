import { colors } from 'styles/colors'
import { predictionScoringTable } from 'util/gameLogic'

export function ScoreLegendItem({ name, points }) {
  return (
    <div style={{ marginRight: '16px', display: 'inline-block' }}>
      {name}
      <span style={{ color: colors.mint, marginLeft: '8px' }}>{points}p</span>
    </div>
  )
}

export function ScoreLegend() {
  return (
    <div
      style={{
        border: `1px solid ${colors.line}`,
        padding: '5px 10px',
        borderRadius: '4px',
      }}
    >
      <div>
        <span style={{ color: colors.subtle, marginRight: '32px' }}>
          Score legend
        </span>
        <ScoreLegendItem name="Perfect" points={predictionScoringTable[0]} />
        <ScoreLegendItem name="Off by 1" points={predictionScoringTable[1]} />
        <ScoreLegendItem name="2" points={predictionScoringTable[2]} />
        <ScoreLegendItem name="3" points={predictionScoringTable[3]} />
        <ScoreLegendItem name="4" points={predictionScoringTable[4]} />
        <ScoreLegendItem name="5" points={predictionScoringTable[5]} />
      </div>
    </div>
  )
}

export function ScoreLegendMobile() {
  return (
    <div
      style={{
        border: `1px solid ${colors.line}`,
        padding: '5px 10px',
        borderRadius: '4px',
      }}
    >
      <div>
        <div style={{ color: colors.subtle }}>Score legend </div>
        <ScoreLegendItem name="Perfect" points={predictionScoringTable[0]} />
        <ScoreLegendItem name="1 off" points={predictionScoringTable[1]} />
        <ScoreLegendItem name="2 off" points={predictionScoringTable[2]} />
        <ScoreLegendItem name="3 off" points={predictionScoringTable[3]} />
        <ScoreLegendItem name="4 off" points={predictionScoringTable[4]} />
        <ScoreLegendItem name="5 off" points={predictionScoringTable[5]} />
      </div>
    </div>
  )
}
