import { Box, Grid, Skeleton } from '@mui/material'
import { ListButton } from 'Components/game/ListButton'
import { RoundCardButton } from 'Components/game/RoundCardButton'
import { GameNameBig } from 'Components/game/gameNameBig'
import { GameProfile } from 'Components/game/gameProfile'
import { PickDropButton } from 'Components/game/pickDropButton'
import { WatchlistButton } from 'Components/game/watchlistButton'
import { ExternalLink } from 'Components/interactive/ExternalLink'
import { ChildSpacerH, ChildSpacerV } from 'Components/layout/ChildSpacer'
import { GameAdminControls } from 'Components/panes/gameView/gameAdminControls'
import { GamePredictionsList } from 'Components/panes/gameView/gamePredictionsList'
import { GameViewMobile } from 'Components/panes/gameView/gameViewMobile'
import { InvolvedCompanies } from 'Components/panes/gameView/involvedCompanies'
import { MultiplayerModes } from 'Components/panes/gameView/multiplayerModes'
import { ReadMore } from 'Components/panes/gameView/readMore'
import { UserPredictGame } from 'Components/panes/gameView/userPredictGame'
import { HLine } from 'Components/visual/HLine'
import { SubtleText } from 'Components/visual/SubtleText'
import { WordDotList } from 'Components/visual/WordDotList'
import { ScoreBadge } from 'Components/visual/scoreBadge/scoreBadge'
import { formatDistance } from 'date-fns'
import { useMemo } from 'react'
import {
  Link,
  ScrollRestoration,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { Breakpoint } from 'react-socks'
import { apiEndpoints } from 'showdown-api/api'
import { useGames } from 'store/game/gameContext'
import { useAuth } from 'store/user/authContext'
import { colors } from 'styles/colors'
import { query } from 'styles/mediaQueries'
import { buildTodayZero } from 'util/date'
import { isDropAfterReleaseAllowed, isDropAllowed } from 'util/gameLogic'
import { buildIgdbImageLink } from 'util/image'
import useCachedData from 'util/useCachedData'
import { Platforms } from './platforms'

export const GameView = () => {
  const { slug } = useParams()
  const {
    watchedGames,
    selectedGames,
    selectedGamesOutsideRound,
    calcGameProfilePoints,
  } = useGames()

  const [isLoadingGame, viewedGame] = useCachedData(
    apiEndpoints.game + '/' + slug,
    null,
    [slug]
  )

  const navigate = useNavigate()

  const { user } = useAuth()

  const cachedSelectedGame = useMemo(() => {
    if (!viewedGame) {
      return null
    }

    let selectedGame =
      selectedGames.find((game) => game.id === viewedGame.id) ||
      selectedGamesOutsideRound.find((game) => game.id === viewedGame.id)

    let watchedGame = watchedGames.find((game) => game.id === viewedGame.id)

    return {
      ...viewedGame,
      profilePoints: calcGameProfilePoints(viewedGame),
      selected: !!selectedGame,
      watched: !!watchedGame,
      dropAllowed: isDropAllowed(viewedGame),
      dropAfterReleaseAllowed: isDropAfterReleaseAllowed(viewedGame),
    }
  }, [
    viewedGame,
    watchedGames,
    selectedGames,
    selectedGamesOutsideRound,
    calcGameProfilePoints,
  ])

  if (isLoadingGame) {
    return (
      <>
        <Skeleton
          style={{
            backgroundColor: colors.skeleton,
            height: '300px',
            minWidth: '30%',
            transform: 'none',
          }}
          key={'skeletonGameView'}
        />
      </>
    )
  }

  if (!cachedSelectedGame) {
    return <p>Game not found.</p>
  }

  return (
    <div style={{ marginBottom: '64px' }}>
      <ScrollRestoration />
      <Breakpoint customQuery={query.tabletDown}>
        <GameViewMobile selectedGame={cachedSelectedGame} />
      </Breakpoint>
      <Breakpoint customQuery={query.tabletUp}>
        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault()
            navigate(-1)
          }}
        >
          <span
            className="material-symbols-outlined"
            style={{ verticalAlign: 'text-top' }}
          >
            arrow_back
          </span>{' '}
          Back
        </Link>
        <Box p={1}></Box>
        <ChildSpacerH
          style={{ justifyContent: 'space-between', alignItems: 'end' }}
        >
          <GameNameBig game={cachedSelectedGame} />
          <ChildSpacerH>
            <WatchlistButton
              game={cachedSelectedGame}
              ButtonComponent={RoundCardButton}
            />
            <PickDropButton
              game={cachedSelectedGame}
              ButtonComponent={ListButton}
            />
          </ChildSpacerH>
        </ChildSpacerH>
        {cachedSelectedGame.category !== 'Full game' && (
          <SubtleText>{cachedSelectedGame.category}</SubtleText>
        )}
        <Box p={1}></Box>
        {cachedSelectedGame?.videos?.length > 0 &&
          cachedSelectedGame?.videos[0] && (
            <div>
              <iframe
                title="Game Video"
                width="100%"
                height="475"
                src={`https://www.youtube.com/embed/${cachedSelectedGame.videos[0].video_id}?controls=1&autoplay=0&mute=1&loop=1&playlist=${viewedGame.videos[0].video_id}`}
                frameBorder="0"
              ></iframe>
            </div>
          )}
        {cachedSelectedGame.metacritic > 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: colors.darkPurple,
              borderRadius: 12,
              padding: '16px 32px',
            }}
          >
            Final score
            <ScoreBadge game={cachedSelectedGame} disablePrediction={true} />
          </div>
        )}
        <GameProfile game={cachedSelectedGame} showLabel={true} />
        <Box p={1}></Box>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <ChildSpacerV>
              <div>
                <h4>Release date</h4>
                <div>{cachedSelectedGame.released}</div>
              </div>
              <div>
                <h4>Platforms</h4>
                <Platforms platforms={cachedSelectedGame.platforms} />
              </div>
              <div>
                <InvolvedCompanies
                  list={cachedSelectedGame.involved_companies}
                />
              </div>
              {cachedSelectedGame.summary && (
                <div>
                  <h4>Summary</h4>
                  <ReadMore id="summary" text={cachedSelectedGame.summary} />
                </div>
              )}
              {(cachedSelectedGame?.genres ||
                cachedSelectedGame?.themes?.length > 0) && (
                <div>
                  <h4>Genres & themes</h4>
                  {cachedSelectedGame?.genres && (
                    <div>
                      <WordDotList list={cachedSelectedGame?.genres} />
                    </div>
                  )}
                  {cachedSelectedGame?.themes?.length > 0 && (
                    <div>
                      <WordDotList list={cachedSelectedGame?.themes} />
                    </div>
                  )}
                </div>
              )}
              {(cachedSelectedGame.player_perspectives ||
                cachedSelectedGame.game_modes ||
                cachedSelectedGame.multiplayer_modes) && (
                <div>
                  <h4>Gameplay</h4>
                  <div>
                    <WordDotList
                      list={cachedSelectedGame.player_perspectives}
                    />
                  </div>
                  <div>
                    <WordDotList list={cachedSelectedGame.game_modes} />
                  </div>
                  <MultiplayerModes
                    modes={cachedSelectedGame.multiplayer_modes}
                  />
                </div>
              )}
              {cachedSelectedGame.storyline && (
                <div>
                  <h4>Storyline</h4>
                  <ReadMore id="summary" text={cachedSelectedGame.storyline} />
                </div>
              )}
              <div>
                <ExternalLink
                  url={`${cachedSelectedGame.url}`}
                  label={`Read more on IGDB`}
                  icon="link-external"
                />
                <SubtleText>
                  Updated{' '}
                  {formatDistance(
                    buildTodayZero(),
                    new Date(cachedSelectedGame.updated)
                  )}{' '}
                  ago
                </SubtleText>
              </div>
            </ChildSpacerV>
          </Grid>
          <Grid item xs={5}>
            <GameAdminControls game={cachedSelectedGame} />
            <HLine
              label={
                cachedSelectedGame.metacritic > 0
                  ? 'Closest predictions'
                  : 'Player review score predictions'
              }
            />
            <Box p={1} />
            <GamePredictionsList game={cachedSelectedGame} />
            <UserPredictGame game={cachedSelectedGame} />
          </Grid>
        </Grid>
        {cachedSelectedGame.screenshots &&
          cachedSelectedGame.screenshots.length > 0 &&
          cachedSelectedGame.screenshots[0] && (
            <img
              src={buildIgdbImageLink(
                cachedSelectedGame.screenshots[0].image_id,
                'screenshot_med_2x'
              )}
              alt="game"
              style={{ width: '100%' }}
            />
          )}
        {cachedSelectedGame.screenshots &&
          cachedSelectedGame.screenshots.length > 0 &&
          cachedSelectedGame.screenshots
            .slice(1, cachedSelectedGame.screenshots.length)
            .map((shot, index) => {
              return (
                <img
                  key={index}
                  src={buildIgdbImageLink(shot.image_id, 'screenshot_med_2x')}
                  alt="game"
                  style={{
                    width: '50%',
                    height: '280px',
                    objectFit: 'cover',
                  }}
                />
              )
            })}
      </Breakpoint>
      {user?.admin && (
        <SubtleText>
          Game ID (hidden for players): {cachedSelectedGame.id}
        </SubtleText>
      )}
    </div>
  )
}
