import { colors } from 'styles/colors'

export const Bar = ({ progress }) => {
  return (
    <div
      style={{
        height: 4,
        width: '100%',
        backgroundColor: colors.white,
      }}
    >
      <div
        style={{
          height: 4,
          width: `${progress > 100 ? 100 : progress}%`,
          backgroundColor: colors.mint,
        }}
      ></div>
    </div>
  )
}
