export const colors = {
  // Base
  primary: '#00061C',
  body: '#00061C',
  line: '#26282e',
  black: '#00061C',
  white: '#fff',
  mint: '#F3B71D',
  mintText: '#6bb3d6',
  pink: '#df27ef', // stale warning
  subtleHeroGradient: 'linear-gradient(#df27ef, #9d56e4)',
  heroGradient: 'linear-gradient(245deg, #df27ef, #8C56F7, #3787FF)',
  subtle: '#747474', // various text

  /// new
  gold: 'gold', // cookie
  positive: '#33BB66', // cookie
  skeleton: '#26282e', // loading skeletons
  red: '#ff004d', // flame
  textLight: '#ccc', // game dates
  disabled: '#ccc', // borderbutton only?
  warning: '#ff9933',

  /// unused
  darkPurple: '#3F3E9A',
}
