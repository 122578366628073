import { Box } from '@mui/material'
import { FilterButtonDrawer } from 'Components/interactive/filterButtonDrawer'
import { HBox } from 'Components/layout'
import { ChildSpacerH, ChildSpacerV } from 'Components/layout/ChildSpacer'
import { Breakpoint } from 'react-socks'
import { query } from 'styles/mediaQueries'
import { GameFilter } from './gameFilter'
import { GameFilterName } from './gameFilterName'
import { GameSelectionCount } from './gameFilterResultCount'
import { GameSortButtonGroup } from './gameSortButtonGroup'

export const GameFilterControls = ({
  filteredSelectedGames,
  isLoadingGames,
}) => {
  return (
    <>
      <Breakpoint customQuery={query.desktopLDown}>
        <ChildSpacerV>
          <GameFilterName />
          <FilterButtonDrawer
            drawerButtonLabel={'Filter'}
            anchor="left"
            ContentComponent={GameFilter}
          />
          {filteredSelectedGames.length > 0 && (
            <HBox align="center-right">
              <GameSortButtonGroup />
            </HBox>
          )}
          {!isLoadingGames && (
            <GameSelectionCount selectionCount={filteredSelectedGames.length} />
          )}
        </ChildSpacerV>
      </Breakpoint>
      <Breakpoint customQuery={query.desktopLUp}>
        <ChildSpacerV>
          <HBox align="center-left" flexType="fill">
            {!isLoadingGames && (
              <GameSelectionCount
                selectionCount={filteredSelectedGames.length}
              />
            )}
            <ChildSpacerH style={{ flex: '1' }}>
              {filteredSelectedGames.length > 0 && (
                <HBox flexType="fill">
                  <Box display="flex" flex={1} />
                  <GameSortButtonGroup />
                </HBox>
              )}
            </ChildSpacerH>
          </HBox>
        </ChildSpacerV>
      </Breakpoint>
    </>
  )
}
