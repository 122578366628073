import { add, format, subDays } from 'date-fns'
import { buildTodayZero } from './date'
import { sortByProperty } from './sort'

export const buildNextRound = (currentRound) => {
  return __buildNextRoundInternal(currentRound.endDate)
}

function __buildNextRoundInternal(dateWithinRound) {
  return buildRoundFromDate(
    add(buildRoundFromDate(dateWithinRound).endDate, { days: 1 })
  )
}

function buildFutureRound(futureRoundsCount) {
  const currentRound = buildCurrentRound()
  return __buildFutureRoundInternal(currentRound.endDate, futureRoundsCount)
}

function __buildFutureRoundInternal(dateWithinRound, futureRoundsCount) {
  return buildRoundFromDate(
    add(buildRoundFromDate(dateWithinRound).endDate, {
      days: 84 * (futureRoundsCount - 1) + 1,
    })
  )
}

export const generateAllRoundsForUser = (user, currentRound) => {
  const userRoundIds = Object.keys(user?.team?.rounds ?? [])
  const currentRoundId = currentRound.id

  const hasCurrentRoundId = userRoundIds.find((item) => item === currentRoundId)
  if (!hasCurrentRoundId) {
    userRoundIds.push(currentRoundId)
  }

  const userRounds = buildRoundsFromIds(userRoundIds)
  userRounds.sort((a, b) => sortByProperty(a, b, 'id'))

  return userRounds
}

export const generateAllRounds = () => {
  const startYear = 2021

  const maxRound = buildFutureRound(4)
  const maxYear = maxRound.year

  const allYears = []

  let year = startYear
  while (year < maxYear) {
    allYears.push(year)
    year++
  }
  allYears.push(maxYear)

  const allRoundIds = []
  allYears.forEach((year) => {
    for (let i = 1; i <= 4; i++) {
      if (year !== maxYear || (year === maxYear && i <= maxRound.roundIndex)) {
        allRoundIds.push(year + `Q` + i)
      }
    }
  })
  const rounds = buildRoundsFromIds(allRoundIds)
  rounds.sort((a, b) => sortByProperty(a, b, 'id'))

  return rounds
}

export const buildCurrentRound = () => {
  return buildRoundFromDate(buildTodayZero())
}

export const buildPreviousRound = (currentRound) => {
  const lastDayOfPreviousRound = subDays(currentRound.startDate, 1)
  return buildRoundFromDate(lastDayOfPreviousRound)
}

export const buildRoundsFromIds = (roundIds) => {
  return roundIds.map((roundId) => {
    return buildRoundFromId(roundId)
  })
}

function splitRoundId(roundId) {
  return { year: roundId.substring(0, 4), qIndex: roundId.substring(5) }
}

function buildRoundFromId(roundId) {
  const roundIdPair = splitRoundId(roundId)

  return buildRoundFromQIndex(roundIdPair.year, parseInt(roundIdPair.qIndex))
}

function buildRoundFromDate(today) {
  const currentYear = format(today, 'yyyy')
  const monthIndex = format(today, 'M')

  let qIndex = 0
  if (monthIndex <= 3) {
    qIndex = 1
  } else if (monthIndex <= 6) {
    qIndex = 2
  } else if (monthIndex <= 9) {
    qIndex = 3
  } else {
    qIndex = 4
  }

  return buildRoundFromQIndex(currentYear, qIndex)
}

function buildRoundFromQIndex(currentYear, qIndex) {
  const endMonthIndex = qIndex * 3
  const startMonthIndex = endMonthIndex - 2
  const startDate = new Date(
    `${currentYear}-${
      startMonthIndex < 10 ? `0${startMonthIndex}` : startMonthIndex
    }-01`
  )

  const formattedEndDate = `${currentYear}-${
    endMonthIndex < 10 ? `0${endMonthIndex}` : endMonthIndex
  }-${qIndex === 1 || qIndex === 4 ? '31' : '30'}`
  const endDate = new Date(formattedEndDate)

  const startMonthName = format(startDate, 'MMMM')
  const startMonthShortName = format(startDate, 'MMM')
  const endMonthName = format(endDate, 'MMMM')
  const endMonthShortName = format(endDate, 'MMM')

  const quarterName = `Q${qIndex}`
  return {
    id: `${currentYear}${quarterName}`,
    year: currentYear,
    fullName: `${currentYear} ${quarterName}`,
    roundName: quarterName,
    roundIndex: qIndex,
    startDate,
    endDate,
    startMonthName,
    startMonthShortName,
    endMonthName,
    endMonthShortName,
  }
}
