import { Box } from '@mui/material'
import { CardGrid } from 'Components/game/cardGrid'
import { Cols } from 'Components/layout/cols'
import { useMemo } from 'react'
import { Breakpoint } from 'react-socks'
import { useGames } from 'store/game/gameContext'
import { useGameFilter } from 'store/game/gameFilterContext'
import { usePredictions } from 'store/game/predictionsContext'
import { useAuth } from 'store/user/authContext'
import { query } from 'styles/mediaQueries'
import {
  getGamePredictionScore,
  getGamePredictions,
  isDropAfterReleaseAllowed,
  isDropAllowed,
} from 'util/gameLogic'
import { sortByProperty, sortByReleasedAndName } from 'util/sort'
import { GameFilter } from './gameFilter'
import { GameFilterControls } from './gameFilterControls'

export const GameFilterView = () => {
  const { filteredGames, gameSortMode, gameFilter, GAME_SORT_OPTIONS } =
    useGameFilter()

  const {
    isLoadingAvailableGames,
    selectedGames,
    watchedGames,
    calcGameProfilePoints,
  } = useGames()

  const { user } = useAuth()

  const { roundGamePredictions } = usePredictions()

  const filteredSelectedGames = useMemo(() => {
    const result =
      filteredGames?.map((game) => {
        let isGameSelected = false

        isGameSelected =
          selectedGames.find((selectedGame) => selectedGame.id === game.id) !==
          undefined

        return {
          ...game,
          profilePoints: calcGameProfilePoints(game),
          selected: isGameSelected,
          predictions: getGamePredictions(roundGamePredictions, game.id),
          yourPrediction: getGamePredictionScore(user, game),
          watched: watchedGames.find(
            (watchedGame) => watchedGame.id === game.id
          ),
          dropAllowed: isDropAllowed(game),
          dropAfterReleaseAllowed: isDropAfterReleaseAllowed(game),
        }
      }) ?? []

    const sortedResult = result.sort((a, b) => {
      if (gameSortMode === GAME_SORT_OPTIONS.releaseDate) {
        return sortByReleasedAndName(
          a,
          b,
          gameFilter.releaseStatus !== 'UNRELEASED' ? 'DESC' : 'ASC'
        )
      } else if (gameSortMode === GAME_SORT_OPTIONS.likelyToScore) {
        return sortByProperty(a, b, 'profilePoints', 'DESC')
      } else if (gameSortMode === GAME_SORT_OPTIONS.score) {
        return sortByProperty(a, b, 'metacritic', 'DESC')
      } else if (gameSortMode === GAME_SORT_OPTIONS.yourPrediction) {
        return sortByProperty(a, b, 'yourPrediction', 'DESC')
      } else if (gameSortMode === GAME_SORT_OPTIONS.communityPrediction) {
        return sortByProperty(a.predictions, b.predictions, 'average', 'DESC')
      } else {
        return sortByReleasedAndName(a, b)
      }
    })
    return sortedResult
  }, [
    selectedGames,
    filteredGames,
    GAME_SORT_OPTIONS,
    gameSortMode,
    gameFilter,
    calcGameProfilePoints,
    user,
    roundGamePredictions,
  ])

  return (
    <>
      <Breakpoint customQuery={query.desktopLDown}>
        <GameFilterControls
          isLoadingGames={isLoadingAvailableGames}
          filteredSelectedGames={filteredSelectedGames}
        />
        <Box p={1}></Box>
        <CardGrid
          isLoading={isLoadingAvailableGames}
          list={filteredSelectedGames}
          animateDirection={-1}
        />
      </Breakpoint>
      <Breakpoint customQuery={query.desktopLUp}>
        <Cols set="auto 350px" gap={32}>
          <div>
            <GameFilterControls
              isLoadingGames={isLoadingAvailableGames}
              filteredSelectedGames={filteredSelectedGames}
            />
            <CardGrid
              isLoading={isLoadingAvailableGames}
              list={filteredSelectedGames}
              animateDirection={-1}
            />
          </div>
          <GameFilter />
        </Cols>
      </Breakpoint>
    </>
  )
}
