import { ToggleButton, ToggleButtonGroup, useMediaQuery } from '@mui/material'
import { useGameFilter } from 'store/game/gameFilterContext'
import { query } from 'styles/mediaQueries'

export const GameSortButtonGroup = () => {
  const { gameSortMode, setGameSortMode, gameFilter, GAME_SORT_OPTIONS } =
    useGameFilter()

  const isMobile = useMediaQuery(query.mobile)

  return (
    <>
      {gameFilter.releaseStatus !== 'UNRELEASED' && (
        <ToggleButtonGroup
          exclusive
          value={gameSortMode}
          onChange={(e, value) => {
            setGameSortMode(value)
          }}
        >
          <ToggleButton value={GAME_SORT_OPTIONS.releaseDate}>
            <span
              className="material-symbols-outlined"
              style={{ marginLeft: '-4px' }}
            >
              expand_more
            </span>
            <span style={{ fontSize: '13px' }}>
              {GAME_SORT_OPTIONS.releaseDate}
            </span>
          </ToggleButton>
          <ToggleButton value={GAME_SORT_OPTIONS.likelyToScore}>
            {' '}
            <span
              className="material-symbols-outlined"
              style={{ marginLeft: '-4px' }}
            >
              expand_more
            </span>
            <span style={{ fontSize: '13px' }}>
              {GAME_SORT_OPTIONS.likelyToScore}
            </span>
          </ToggleButton>
          <ToggleButton value={GAME_SORT_OPTIONS.yourPrediction}>
            <span
              className="material-symbols-outlined"
              style={{ marginLeft: '-4px' }}
            >
              expand_more
            </span>
            {isMobile ? 'Guess' : GAME_SORT_OPTIONS.yourPrediction}
          </ToggleButton>
          {/* <ToggleButton value={GAME_SORT_OPTIONS.communityPrediction}>
            <span
              className="material-symbols-outlined"
              style={{ marginLeft: '-4px' }}
            >
              expand_more
            </span>
            {isMobile ? 'Com.' : GAME_SORT_OPTIONS.communityPrediction}
          </ToggleButton> */}
          <ToggleButton value={GAME_SORT_OPTIONS.score}>
            {' '}
            <span
              className="material-symbols-outlined"
              style={{ marginLeft: '-4px' }}
            >
              expand_more
            </span>
            <span style={{ fontSize: '13px' }}>{GAME_SORT_OPTIONS.score}</span>
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      {gameFilter.releaseStatus === 'UNRELEASED' && (
        <ToggleButtonGroup
          exclusive
          value={gameSortMode}
          onChange={(e, value) => {
            setGameSortMode(value)
          }}
        >
          <ToggleButton value={GAME_SORT_OPTIONS.releaseDate}>
            <span
              className="material-symbols-outlined"
              style={{ marginLeft: '-4px' }}
            >
              expand_less
            </span>
            {GAME_SORT_OPTIONS.releaseDate}
          </ToggleButton>
          <ToggleButton value={GAME_SORT_OPTIONS.likelyToScore}>
            <span
              className="material-symbols-outlined"
              style={{ marginLeft: '-4px' }}
            >
              expand_more
            </span>
            {GAME_SORT_OPTIONS.likelyToScore}
          </ToggleButton>
          <ToggleButton value={GAME_SORT_OPTIONS.yourPrediction}>
            <span
              className="material-symbols-outlined"
              style={{ marginLeft: '-4px' }}
            >
              expand_more
            </span>
            {isMobile ? 'Guess' : GAME_SORT_OPTIONS.yourPrediction}
          </ToggleButton>
          <ToggleButton value={GAME_SORT_OPTIONS.communityPrediction}>
            <span
              className="material-symbols-outlined"
              style={{ marginLeft: '-4px' }}
            >
              expand_more
            </span>
            {isMobile ? 'Com.' : GAME_SORT_OPTIONS.communityPrediction}
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </>
  )
}
