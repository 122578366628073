import { Box } from '@mui/material'
import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { Cols } from 'Components/layout/cols'
import { Breakpoint } from 'react-socks'
import { query } from 'styles/mediaQueries'
import { spacing } from 'styles/spacing'
import { colors } from './../../styles/colors'
import { AboutUs } from './aboutUs'
import { Contact } from './contact'
import { MobileFooter } from './mobileFooter'

export const Footer = () => {
  return (
    <>
      <Breakpoint customQuery={query.mobile}>
        <MobileFooter />
      </Breakpoint>
      <Breakpoint customQuery={query.mobileToTablet}>
        <ChildSpacerV>
          <Contact />
          <Box p={2} />
          <AboutUs />
        </ChildSpacerV>
      </Breakpoint>
      <Breakpoint customQuery={query.tabletUp}>
        <Box
          style={{
            backgroundColor: colors.body,
            padding: `${spacing.pageTablet} ${spacing.pageTablet} ${spacing.pageTablet} ${spacing.pageTablet}`,
          }}
        >
          <Cols set="1fr 1fr" gap={64}>
            <ChildSpacerV>
              <Contact />
            </ChildSpacerV>
            <div>
              <AboutUs />
            </div>
          </Cols>
        </Box>
      </Breakpoint>
    </>
  )
}
