import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { ErrorSummary } from 'Components/form/errorSummary'
import { IconButton } from 'Components/interactive'
import { ExternalLink } from 'Components/interactive/ExternalLink'
import { HBox } from 'Components/layout'
import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { HeroMessage } from 'Components/panes/heroMessage'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { apiEndpoints } from 'showdown-api/api'
import { apiPost } from 'showdown-api/rest'
import { browserState } from 'store/browserState'
import { useAuth } from 'store/user/authContext'
import { PrivacyPolicy } from '../../../Components/panes/privacyPolicy'

export const SignupView = () => {
  const { signInUser } = useAuth()
  const navigate = useNavigate()

  const [formState, setFormState] = useState({
    teamName: '',
    email: '',
    password: '',
    confirmPassword: '',
    errors: [],
    loading: false,
  })

  const handleChange = (event) => {
    const value = event.target.value
    setFormState({ ...formState, [event.target.name]: value })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setFormState({ ...formState, loading: true })

    const newUserData = {
      email: formState.email,
      password: formState.password,
      confirmPassword: formState.confirmPassword,
      teamName: formState.teamName,
      inviteCode: formState.inviteCode,
    }
    await apiPost(apiEndpoints.signup, newUserData)
      .then((data) => {
        setFormState({ ...formState, loading: false, errors: [] })
        signInUser(data.idToken)
        browserState.clearSignupData()
        navigate('/')
      })
      .catch((error) => {
        if (error) {
          setFormState({
            ...formState,
            errors: error.message,
            loading: false,
          })
        }
      })
  }

  const { errors, loading } = formState
  return (
    <ChildSpacerV>
      <Typography component="h2" variant="h2">
        Create your profile
      </Typography>
      <HeroMessage padding="24px">
        Critical Showdown is now open beta. Enter <em>onemoreround</em> as your
        invite code.
      </HeroMessage>
      Join our Discord to follow the site progress.
      <HBox style={{ alignItems: 'baseline' }}>
        <ExternalLink
          url="https://discord.gg/KrrCYAx9vZ"
          label="Critical Showdown Discord"
        />
      </HBox>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            variant="filled"
            color="primary"
            id="inviteCode"
            label="Invite code"
            name="inviteCode"
            helperText={errors?.inviteCode ?? ''}
            error={errors?.inviteCode ? true : false}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            variant="filled"
            color="primary"
            id="teamName"
            label="Profile name (5-25 characters, A-z)"
            name="teamName"
            helperText={errors?.teamName ?? ''}
            error={errors?.teamName ? true : false}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            required
            fullWidth
            id="email"
            label="Email address"
            name="email"
            type="email"
            autoComplete="email"
            helperText={errors?.email ?? ''}
            error={errors?.email ? true : false}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            helperText={errors?.password ?? ''}
            error={errors?.password ? true : false}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="filled"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm password"
            type="password"
            id="confirmPassword"
            autoComplete="current-password"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <IconButton
        label="Sign up"
        onClick={handleSubmit}
        loading={loading}
        disabled={
          loading ||
          !formState.inviteCode ||
          !formState.teamName ||
          !formState.email ||
          !formState.password ||
          !formState.confirmPassword
        }
        size="large"
      />
      <ErrorSummary label="Could not sign up" errors={errors} />
      <Box p={1} />
      <PrivacyPolicy />
    </ChildSpacerV>
  )
}
