import { parseISO } from 'date-fns'
import * as React from 'react'
import { useState } from 'react'
import { apiEndpoints } from 'showdown-api/api'
import { apiPost } from 'showdown-api/rest'
import { useAuth } from 'store/user/authContext'
import {
  getGamePredictionScore,
  getGamePredictions,
  isGameReleased,
} from 'util/gameLogic'
import { sortByProperty, sortByReleasedAndName } from 'util/sort'
import useCachedData from 'util/useCachedData'
import { buildTodayZero, isWithinDaysAfter } from './../../util/date'
import { gameProfileService } from './gameProfile'
import { usePredictions } from './predictionsContext'
import { useRounds } from './roundContext'

const GamesContext = React.createContext()

export { GamesProvider, useGames }

function GamesProvider({ children }) {
  const { selectedRound, isInCurrentRound, mainRounds } = useRounds()
  const { roundGamePredictions } = usePredictions()

  // Load available games to pick
  const [isLoadingRoundGames, roundGames] = useCachedData(
    apiEndpoints.games_by_round + '?roundId=' + selectedRound.id,
    [],
    null,
    null,
    28800000,
    'post'
  )

  const [isLoadingGameProfile, gameProfile] = useCachedData(
    apiEndpoints.game_profile,
    {}
  )

  // User selected games
  const { user } = useAuth()
  const [userGames, setUserGames] = useState([])
  const [isLoadingUserGames, setIsLoadingUserGames] = useState([])

  // User selected games by id and if they are within the selected round
  const [selectedGames, setSelectedGames] = useState([])
  const [selectedGamesOutsideRound, setSelectedGamesOutsideRound] = useState([])

  // User watched games
  const [isLoadingWatchedGames, setIsLoadingWatchedGames] = useState([])
  const [watchedGames, setWatchedGames] = useState([])
  const [userWatchedGames, setUserWatchedGames] = useState([])

  React.useEffect(() => {
    async function manageUserWatchedGames() {
      if (watchedGames.length > 0 && !user.isAuthenticated) {
        console.log('User is not authenticated. Clearing selected games')
        setWatchedGames([])
        setUserWatchedGames([])
        return
      } else if (watchedGames.length === 0 && user.isAuthenticated) {
        console.log('User is authenticated. Loading selected games')
        setIsLoadingWatchedGames(true)

        let userWatchedGamesFromApi = []
        const watchlistKeys = Object.keys(user?.team?.watchlist)
        if (watchlistKeys?.length > 0) {
          const result = await apiPost(apiEndpoints.games_by_id, {
            gameIds: watchlistKeys,
          })

          userWatchedGamesFromApi = result.data
          setUserWatchedGames(userWatchedGamesFromApi ?? [])
        }

        setIsLoadingWatchedGames(false)
      }
    }
    manageUserWatchedGames()
  }, [user.isAuthenticated, user.team, watchedGames, selectedRound])

  React.useEffect(() => {
    async function manageUserGames() {
      if (selectedGames.length > 0 && !user.isAuthenticated) {
        console.log('User is not authenticated. Clearing selected games')
        setSelectedGames([])
        setUserGames([])
        return
      } else if (selectedGames.length === 0 && user.isAuthenticated) {
        console.log('User is authenticated. Loading selected games')
        setIsLoadingUserGames(true)

        const roundGames = user?.team?.rounds.hasOwnProperty(selectedRound.id)
          ? user.team.rounds[selectedRound.id]
          : []

        let userGamesFromApi = []
        if (roundGames.length > 0) {
          const result = await apiPost(apiEndpoints.games_by_id, {
            gameIds: user.team.rounds[selectedRound.id],
          })

          userGamesFromApi = result.data
          setUserGames(userGamesFromApi ?? [])
        }

        setIsLoadingUserGames(false)
      }
    }
    manageUserGames()
  }, [user.isAuthenticated, user.team, selectedGames, selectedRound])

  const calcGameProfilePoints = React.useCallback(
    (game) => {
      return gameProfileService.calcPoints(
        game,
        gameProfile,
        isLoadingGameProfile
      )
    },
    [gameProfile, isLoadingGameProfile]
  )

  // Combine the games from the round with the user selected games
  const combinedGames = React.useMemo(() => {
    let combinedGames = [...roundGames]
    let userSelectedGames = []
    let userSelectedGamesOutsideRound = []

    userGames?.forEach((userGame) => {
      // We know the id and that it was selected by the user. Find out if it is missing data and if it is within the round
      let gameSelected = {
        id: userGame.id,
        selected: true,
        hasData: false,
      }

      const foundGames = roundGames.find(
        (roundGame) => roundGame.id === userGame.id
      )

      if (!foundGames) {
        combinedGames.push(userGame)
      }

      // If the selected round is not the current we pretend it was selected correctly. No need to say it is outside the round
      if (
        selectedRound.id !== mainRounds.current.id ||
        (selectedRound.id === mainRounds.current.id &&
          isInCurrentRound(userGame))
      ) {
        userSelectedGames.push(gameSelected)
      } else {
        userSelectedGamesOutsideRound.push(gameSelected)
      }
    })

    const watchedGames = []
    userWatchedGames?.forEach((watchedGame) => {
      const foundGames = roundGames.find(
        (roundGame) => roundGame.id === watchedGame.id
      )

      if (!foundGames) {
        combinedGames.push({ ...watchedGame, watched: true })
      }

      watchedGames.push({ id: watchedGame.id, watched: true })
    })

    combinedGames = combinedGames.map((game) => {
      return {
        ...game,
        profilePoints: calcGameProfilePoints(game),
        yourPrediction: getGamePredictionScore(user, game),
        predictions: getGamePredictions(roundGamePredictions, game.id),
      }
    })

    setSelectedGames(userSelectedGames)
    setSelectedGamesOutsideRound(userSelectedGamesOutsideRound)
    setWatchedGames(watchedGames)

    return combinedGames
  }, [
    user,
    userGames,
    roundGames,
    userWatchedGames,
    isInCurrentRound,
    selectedRound,
    mainRounds,
    calcGameProfilePoints,
    roundGamePredictions,
  ])

  const sortedReviewedGameList = React.useMemo(() => {
    if (!combinedGames) {
      console.log('Invalid list. Check out why')
      return []
    }

    function isWithinReviewWindow(game) {
      return isWithinDaysAfter(parseISO(game.released), buildTodayZero(), 7)
    }

    return combinedGames
      .filter(
        (game) =>
          game.metacritic !== null &&
          isInCurrentRound(game) &&
          isWithinReviewWindow(game)
      )
      .sort((a, b) => sortByReleasedAndName(a, b, 'DESC'))
  }, [combinedGames, isInCurrentRound])

  const sortedBestScoredGameList = React.useMemo(() => {
    if (!combinedGames) {
      console.log('Invalid list. Check out why')
      return []
    }

    const allGames = [...combinedGames]

    return allGames
      .filter((game) => game.metacritic !== null && isInCurrentRound(game))
      .sort((a, b) => sortByProperty(a, b, 'metacritic', 'DESC'))
  }, [combinedGames, isInCurrentRound])

  const sortedUpcomingGameList = React.useMemo(() => {
    if (!combinedGames) {
      console.log('Invalid list. Check out why')
      return []
    }

    return combinedGames
      .filter((game) => {
        return (
          game.metacritic === null &&
          !isGameReleased(game) &&
          isInCurrentRound(game)
        )
      })
      .sort((a, b) => sortByReleasedAndName(a, b, 'ASC'))
  }, [combinedGames, isInCurrentRound])

  const sortedTopPredictionsGameList = React.useMemo(() => {
    if (!combinedGames) {
      console.log('Invalid list. Check out why')
      return []
    }

    return combinedGames
      .filter((game) => {
        return (
          !isGameReleased(game) && isInCurrentRound(game) && game.predictions
        )
      })
      .sort((a, b) =>
        sortByProperty(a?.predictions, b?.predictions, 'average', 'DESC')
      )
  }, [combinedGames, isInCurrentRound])

  const [gameActionSnackState, setGameActionSnackState] = useState({
    isOpen: false,
    text: '',
    severity: 'success', // success, info, warning, error
  })

  const gamesContext = {
    isLoadingAvailableGames: isLoadingRoundGames,
    availableGames: combinedGames,

    isLoadingGameProfile,
    gameProfile,
    calcGameProfilePoints,

    reviewedList: sortedReviewedGameList,
    bestScoredList: sortedBestScoredGameList,
    upcomingList: sortedUpcomingGameList,
    topPredictionsList: sortedTopPredictionsGameList,
    gameActionSnackState,
    setGameActionSnackState,

    isLoadingUserGames,
    setIsLoadingUserGames,
    userGames,
    setUserGames,
    selectedGames,
    setSelectedGames,
    selectedGamesOutsideRound,
    setSelectedGamesOutsideRound,

    watchedGames,
    setWatchedGames,
    userWatchedGames,
    setUserWatchedGames,
    isLoadingWatchedGames,
    setIsLoadingWatchedGames,
  }

  return (
    <GamesContext.Provider value={gamesContext}>
      {children}
    </GamesContext.Provider>
  )
}

function useGames() {
  const context = React.useContext(GamesContext)
  if (!context) {
    throw new Error(`useGames must be used within a GamesProvider`)
  }
  return context
}
