import { ProfileScore } from 'Components/visual/profileScore'
import { useEffect, useState } from 'react'
import { useLeaderboards } from 'store/leaderboard/leaderboardContext'
import { sortByProperty } from 'util/sort'

export const ProfileScoreContainer = ({ user, selectedRound }) => {
  const { leaderboards, selectedLeaderboardType } = useLeaderboards()
  const [profileScore, setProfileScore] = useState(0)

  useEffect(() => {
    const leaderboard = leaderboards[selectedRound.id]
      ? leaderboards[selectedRound.id].fantasy
      : []
    const teamPosition =
      leaderboard
        .sort((a, b) => sortByProperty(a, b, 'score', 'DESC'))
        .findIndex((entry) => entry.userId === user.id) ?? 0
    const team = leaderboard.find((entry) => entry.userId === user.id) ?? {
      score: 0,
    }

    setProfileScore({
      score: (team && team.score) ?? 0,
      position: teamPosition,
    })
  }, [user, selectedRound.id, leaderboards, selectedLeaderboardType])

  return <ProfileScore profileScore={profileScore} />
}
