import { Box } from '@mui/material'
import { colors } from 'styles/colors'

const styles = {
  wrapper: {
    padding: '0',
    width: '100%',
    position: 'relative',
  },

  line: {
    height: '1px',
    width: '100%',
    backgroundColor: colors.mint,
  },

  text: {
    position: 'absolute',
    backgroundColor: colors.primary,
    padding: '4px 8px',
    marginTop: '-16px',
  },
}

export const LineText = ({ text }) => {
  return (
    <Box display="flex" justifyContent="center" style={styles.wrapper}>
      <div style={styles.line}></div>
      <div style={styles.text}>{text}</div>
    </Box>
  )
}
