import { Box, TextField, useMediaQuery } from '@mui/material'
import { ErrorSummary } from 'Components/form/errorSummary'
import { CSButton } from 'Components/game/CSButton'
import { VBox } from 'Components/layout'
import { useEffect, useMemo, useState } from 'react'
import { apiEndpoints } from 'showdown-api/api'
import { apiPost } from 'showdown-api/rest'
import { usePredictGame } from 'store/game/userPredictGameContext'
import { useAuth } from 'store/user/authContext'
import { colors } from 'styles/colors'
import {
  clamp,
  getGamePredictionScore,
  isGameLocked,
  isGameReleased,
} from 'util/gameLogic'
import { query } from '../../../styles/mediaQueries'
import { ChildSpacerV } from '../../layout/ChildSpacer'
import { HBox } from '../../layout/HBox'
import { PredictionBadge } from './predictionBadge'
import { useUINotifs } from 'store/game/uiNotificationsContext'

export const UserPredictGame = ({ game }) => {
  const { setGameActionSnackState } = useUINotifs()
  const { user } = useAuth()
  const { onPredictGame } = usePredictGame()

  const isMobile = useMediaQuery(query.mobile)

  const prediction = useMemo(() => {
    const prediction = getGamePredictionScore(user, game)
    return prediction
  }, [user, game])

  const [formState, setFormState] = useState({
    score: prediction ?? '',
    errors: {},
    saving: false,
    predictionSaved: false,
  })

  useEffect(() => {
    setFormState({
      errors: {},
      saving: false,
      score: prediction,
      predictionSaved: false,
    })
  }, [prediction])

  if (!user.isAuthenticated) {
    return null
  }

  // Don't show controls for released games, show result instead
  if (game.metacritic) {
    return (
      <>
        <Box p={1} />
        <PredictionBadge game={game} prediction={prediction} />
        <Box p={1} />
      </>
    )
  }

  if (isGameLocked(game) || isGameReleased(game)) {
    return null
  }

  const handleChange = (event) => {
    const value = event.target.value

    setFormState({ ...formState, [event.target.name]: value })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    let score = clamp(formState.score, 0, 100)

    setFormState({ ...formState, score, saving: true })

    const newPrediction = {
      gameId: game.id,
      userId: user.id,
      teamId: user.team.id,
      score,
    }

    await apiPost(apiEndpoints.team_prediction_save, newPrediction)
      .then((data) => {
        setFormState({
          ...formState,
          score,
          predictionSaved: true,
          saving: false,
          errors: [],
        })
        onPredictGame(newPrediction)

        setGameActionSnackState({
          isOpen: true,
          text: `Nice prediction! Check back when the game has been reviewed to see how
          close you were.`,
          severity: 'success',
        })

        let t = setTimeout(() => {
          setFormState({
            ...formState,
            predictionSaved: false,
          })
          clearTimeout(t)
        }, 5000)
      })
      .catch((error) => {
        if (error) {
          console.error(error)

          setFormState({
            ...formState,
            score,
            errors: [error.message] ?? {},
            saving: false,
          })
        }
      })
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event)
      event.preventDefault()
    }
  }

  const { errors, saving } = formState

  return (
    <>
      <ChildSpacerV>
        {!isMobile && (
          <VBox>
            <TextField
              variant="filled"
              color="primary"
              id="score"
              label="Your prediction (0-100)"
              name="score"
              type="number"
              style={{ width: '100%' }}
              helperText={errors.score}
              error={errors.score ? true : false}
              onChange={handleChange}
              value={formState.score}
              onKeyDown={handleKeyDown}
            />
            <CSButton
              label="Save&nbsp;prediction"
              onClick={handleSubmit}
              saving={saving}
              style={{ width: '100%' }}
              color={colors.mint}
              disabled={saving || formState.score === ''}
            />
          </VBox>
        )}
        {isMobile && (
          <HBox>
            <TextField
              hiddenLabel
              size="small"
              placeholder={'Your prediction (0-100)'}
              variant="filled"
              color="primary"
              id="score"
              name="score"
              type="number"
              sx={{ height: '44px' }}
              style={{ width: '100%' }}
              helperText={errors.score}
              error={errors.score ? true : false}
              onChange={handleChange}
              value={formState.score}
              onKeyDown={handleKeyDown}
            />
            <CSButton
              label="Save"
              onClick={handleSubmit}
              saving={saving}
              color={colors.mint}
              disabled={saving || formState.score === ''}
            />
          </HBox>
        )}
      </ChildSpacerV>
      <ErrorSummary label="Could not save" errors={errors} />
    </>
  )
}
