import { colors } from 'styles/colors'
import { Typography } from '@mui/material'

export const SubtleText = ({ text, children, nowrap }) => {
  return (
    <Typography
      style={{
        color: colors.subtle,
        fontSize: '12px',
        fontWeight: 'bold',
        textShadow: `1px 1px 2px ${colors.black}`,
        whiteSpace: nowrap ? 'nowrap' : 'normal',
      }}
    >
      {text || children}
    </Typography>
  )
}
