import { ProfilePanelView } from './profilePanelView'
import { colors } from 'styles/colors'

export const ProfilePanel = () => {
  return (
    <aside
      style={{
        backgroundColor: colors.body,
      }}
    >
      <div
        id="sidebar-content"
        style={{
          margin: '48px',
          width: 'calc(400px - 96px)',
          right: 0,
          top: 0,
        }}
      >
        <ProfilePanelView />
      </div>
    </aside>
  )
}
