import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { SubtleText } from 'Components/visual/SubtleText'
import { useMemo, useState } from 'react'
import { Bar, BarChart, XAxis, YAxis } from 'recharts'
import { useGames } from 'store/game/gameContext'
import { useRounds } from 'store/game/roundContext'
import { isWithin } from 'util/date'
import { sortByProperty } from 'util/sort'
import { colors } from '../../../styles/colors'

export const FantasyLeaderboardChart = () => {
  const { isLoadingAvailableGames, availableGames } = useGames()
  const { selectedRound } = useRounds()

  const [totalGames, setTotalGames] = useState(0)

  const data = useMemo(() => {
    const result =
      availableGames
        ?.filter(
          (game) =>
            game.metacritic !== null &&
            isWithin(
              game.released,
              selectedRound.startDate,
              selectedRound.endDate
            )
        )
        ?.map((game) => {
          return {
            score: game.metacritic,
          }
        }) ?? []

    setTotalGames(result.length)

    let scoreCountMap = {}

    result.forEach((game) => {
      let collectionName = game.score < 65 ? '< 65' : game.score

      if (!scoreCountMap[collectionName]) {
        scoreCountMap[collectionName] = 0
      }
      scoreCountMap[collectionName]++
    })

    let scoreCounts = []

    Object.keys(scoreCountMap).forEach((key) => {
      scoreCounts.push({ name: key, score: scoreCountMap[key] })
    })

    return scoreCounts.sort((a, b) => sortByProperty(a, b, 'name', 'DESC'))
  }, [availableGames, selectedRound])

  if (data.length === 0) {
    return null
  }

  const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    return (
      <text
        x={x + width / 2}
        y={y}
        fill="#666"
        textAnchor="middle"
        dy={-6}
      >{`${value}`}</text>
    )
  }

  const renderBarChart = (
    <>
      <BarChart width={800} height={120} data={data}>
        <XAxis dataKey="name" />
        <YAxis />
        <Bar
          dataKey="score"
          barSize={30}
          fill={colors.mint}
          label={renderCustomBarLabel}
        />
      </BarChart>
      <SubtleText text={`Total games this round: ${totalGames}`} />
    </>
  )

  return (
    <>
      {!isLoadingAvailableGames && (
        <ChildSpacerV>
          <h3>Score spread during round (&lt; 65 are merged)</h3>
          {renderBarChart}
        </ChildSpacerV>
      )}
    </>
  )
}
