let apiHost = 'http://localhost:5001/game-fantasy-league/europe-west1/api'

const testProdAPI = false
if (process.env.NODE_ENV === 'production' || testProdAPI) {
  apiHost = 'https://europe-west1-game-fantasy-league.cloudfunctions.net/api'
}

/** Add new functions endpoints here
 *
 * "user" becomes "<host>/user"
 */
const generateApi = () => {
  const apiUrlNames = [
    'user',
    'signup',
    'signup/external',
    'login',
    'team',
    'team/fantasy/add',
    'team/fantasy/drop',
    'team/prediction/save',
    'team/watchlist/add',
    'team/watchlist/drop',
    'leaderboard',
    'maintenance/update-leaderboard',
    'maintenance/update',
    'game',
    'game-profile',
    'games',
    'games-by-id',
    'games-by-round',
    'predictions-by-round',
    'game/admin/save',
  ]

  const API = {}
  for (const urlName of apiUrlNames) {
    API[urlName.replace(/[/-]/g, '_')] = `${apiHost}/${urlName}`
  }

  return API
}

const API = generateApi()

export const apiEndpoints = API
