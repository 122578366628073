import { Checkbox, FormControlLabel } from '@mui/material'
import { VBox } from 'Components/layout'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { SubtleText } from 'Components/visual/SubtleText'
import { colors } from 'styles/colors'
import { ScoreLegend } from '../leaderboards/scoreLegend'
import { UserPanelViewHeader } from '../profilePanel/userPanelViewHeader'
import { InspectPredictionBadge } from './inspectPredictionBadge'
import { PredictionsSortButton } from './predictionsSortButton'

export const TeamPredictionsListView = ({
  inspectedUser,
  setShowNotReleased,
  showNotReleased,
  setShowNoPoints,
  showNoPoints,
  predictionsSortMode,
  toggleSortMode,
}) => {
  return (
    <div>
      <ChildSpacerVAll>
        <VBox align="center">
          <UserPanelViewHeader user={inspectedUser} />
          <div
            style={{
              color: colors.mint,
              fontSize: '32px',
              lineHeight: '1.2em',
            }}
          >
            {inspectedUser.team.totalPoints}p
          </div>
          <SubtleText text="Total prediction points"></SubtleText>
        </VBox>

        <h3>Predictions breakdown</h3>

        <ScoreLegend />
        <VBox>
          <span style={{ marginRight: 16 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showNotReleased}
                  onChange={() => setShowNotReleased(!showNotReleased)}
                  name="showNotReleased"
                />
              }
              label="Show not released"
            />
          </span>
          <FormControlLabel
            control={
              <Checkbox
                checked={showNoPoints}
                onChange={() => setShowNoPoints(!showNoPoints)}
                name="showNoPoints"
              />
            }
            label="Show not scored"
          />
        </VBox>
        <PredictionsSortButton
          predictionsSortMode={predictionsSortMode}
          toggleSortMode={toggleSortMode}
        />

        {inspectedUser?.team?.predictions.map((prediction) => (
          <InspectPredictionBadge
            key={prediction.game.id}
            game={prediction.game}
            predictionResult={prediction.predictionResult}
          />
        ))}

        {inspectedUser?.team?.predictions?.length === 0 && (
          <div>No games shown. Try selecting another filter.</div>
        )}
      </ChildSpacerVAll>
    </div>
  )
}
