import { Button, Typography, useMediaQuery } from '@mui/material'
import { differenceInDays, isAfter, isBefore } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { useRounds } from 'store/game/roundContext'
import { colors } from 'styles/colors'
import { query } from 'styles/mediaQueries'
import { buildTodayZero } from 'util/date'
import { Bar } from './Bar'

const buildRoundProgressPercent = (round) => {
  const today = buildTodayZero()

  if (isAfter(today, round.endDate)) {
    return 100
  }
  if (isBefore(today, round.startDate)) {
    return 0
  }

  const roundDays = differenceInDays(round.endDate, round.startDate)
  const diffInDays = differenceInDays(today, round.startDate)
  return (diffInDays / roundDays) * 100
}

export const RoundTimeLine = () => {
  const { selectedRound } = useRounds()

  const roundProgressPercent = buildRoundProgressPercent(selectedRound)

  const navigate = useNavigate()

  const isMobile = useMediaQuery(query.mobile)

  const startMonthName = isMobile
    ? selectedRound.startMonthShortName
    : selectedRound.startMonthName
  const endMonthName = isMobile
    ? selectedRound.endMonthShortName
    : selectedRound.endMonthName

  return (
    <div id="timeline" style={{ display: 'flex' }}>
      <Button
        onClick={() => navigate('/select-round')}
        style={{
          padding: '16px',
          margin: '0 -16px 0 -16px',
          color: colors.white,
          display: 'block',
          flex: 1,
        }}
      >
        <div>
          <Typography component="h2" variant="h2" style={{ fontSize: '14px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span
                style={{
                  fontSize: '18px',
                  whiteSpace: 'nowrap',
                  marginRight: '16px',
                }}
              >
                {selectedRound.fullName}
              </span>
              <span
                style={{
                  whiteSpace: 'nowrap',
                  textAlign: 'right',
                }}
              >
                {startMonthName}

                <span
                  className="material-symbols-outlined"
                  style={{
                    fontSize: '18px',
                    color: colors.mint,
                    verticalAlign: 'middle',
                  }}
                >
                  arrow_forward
                </span>
                {endMonthName}
              </span>
            </div>
          </Typography>
          <Bar progress={roundProgressPercent} />
        </div>
      </Button>
    </div>
  )
}
