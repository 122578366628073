import { HLine } from 'Components/visual/HLine'
import { colors } from 'styles/colors'
import { ExternalLink } from '../interactive/ExternalLink'
import { LeaderboardsUpdatedText } from './leaderboardsUpdatedText'

export const Contact = () => {
  return (
    <>
      <HLine icon="contact_support" label="Contact" />
      <div>
        <span
          className="material-symbols-outlined"
          style={{ fontSize: 18, marginRight: 8, color: colors.mint }}
        >
          forum
        </span>
        <ExternalLink
          url="https://discord.gg/KrrCYAx9vZ"
          label="Critical Showdown Discord"
        />
      </div>
      <div>
        <span
          className="material-symbols-outlined"
          style={{ fontSize: 18, marginRight: 8, color: colors.mint }}
        >
          chat
        </span>
        <ExternalLink
          url="https://x.com/critshowdown"
          label="@CritShowdown X/Twitter"
        />
      </div>
      <div>
        <span
          className="material-symbols-outlined"
          style={{ fontSize: 18, marginRight: 8, color: colors.mint }}
        >
          shopping_bag
        </span>
        <ExternalLink
          url="https://critical-showdown.myspreadshop.se"
          label="Critical Showdown Merch"
        />
      </div>

      <div
        style={{
          color: colors.subtle,
        }}
      >
        Games metadata powered by{' '}
        <a style={{ color: colors.subtle }} href="https://igdb.com">
          IGDB.com
        </a>
      </div>
      <LeaderboardsUpdatedText />
    </>
  )
}
