import { GameName } from 'Components/game/gameName'
import { VBox } from 'Components/layout'
import { ScoreBadge } from 'Components/visual/scoreBadge/scoreBadge'
import { ReleaseDateFormatter } from 'pages/home/profilePanel/releaseDateFormatter'
import { useMemo } from 'react'
import { useGames } from 'store/game/gameContext'
import { HomeList } from './homeList'

export const BestScoredList = () => {
  return <BestScoredListRenderer />
}

const BestScoredListRenderer = () => {
  const { isLoadingAvailableGames, bestScoredList, selectedGames } = useGames()

  const sortedGames = useMemo(() => {
    let sortedGames =
      bestScoredList.map((game) => {
        return {
          ...game,
          sharedGame: !!selectedGames?.find(
            (userGame) => userGame.id === game.id
          ),
        }
      }) ?? []

    return sortedGames
  }, [bestScoredList, selectedGames])

  return (
    <HomeList
      label="Top 10 games so far this round"
      previewItemLimit={10}
      list={sortedGames}
      isLoading={isLoadingAvailableGames}
      markFn={(entry) => entry.sharedGame}
      columns={[
        {
          ColComponent: ({ colData }) => {
            return (
              <VBox>
                <GameName game={colData.entry} />
                <ReleaseDateFormatter
                  releaseDate={colData.entry.released}
                  absolute
                />
              </VBox>
            )
          },
        },
        {
          ColComponent: ({ colData }) => {
            return <ScoreBadge game={colData.entry} />
          },
        },
      ]}
    />
  )
}
