import { Cols } from 'Components/layout/cols'
import { RoundTimeLine } from 'Components/panes/roundTimeLine'
import { Logo } from 'Components/visual'
import { Breakpoint } from 'react-socks'
import { colors } from 'styles/colors'
import { query } from 'styles/mediaQueries'
import { spacing } from 'styles/spacing'
import { TopMenu } from './topMenu'
export const HeaderPanel = () => {
  const headerDesktopStyle = {
    left: 0,
    top: 0,
    padding: `${spacing.pageDesktop} ${spacing.pageDesktop} 0 ${spacing.pageDesktop}`,
    backgroundColor: colors.primary,
    zIndex: 2,
    borderBottom: `1px solid ${colors.body}`,
  }

  const headerTabletStyle = {
    ...headerDesktopStyle,
    padding: `${spacing.pageTablet} ${spacing.pageTablet} 0 ${spacing.pageTablet}`,
  }

  const headerMobileStyle = {
    ...headerDesktopStyle,
    padding: `${spacing.pageMobile} ${spacing.pageMobile} 0 ${spacing.pageMobile}`,
  }

  return (
    <>
      <Breakpoint customQuery={query.mobile}>
        <header style={headerMobileStyle}>
          <Cols set="1fr 2fr" gap={32}>
            <Logo />
            <RoundTimeLine />
          </Cols>
        </header>
      </Breakpoint>
      <Breakpoint customQuery={query.mobileToTablet}>
        <header style={headerTabletStyle}>
          <Cols set="1fr 2fr" gap={32}>
            <Logo />
            <RoundTimeLine />
          </Cols>
        </header>
      </Breakpoint>
      {/*       <Breakpoint customQuery={query.tabletToDesktop}>
      <header style={headerTabletStyle}>
        <Cols set="auto auto auto" gap={32}>
          <Logo />
        </Cols>
        <TopMenu />
      </header>
    </Breakpoint> */}
      <Breakpoint customQuery={query.tabletToDesktopUltra}>
        <header style={headerTabletStyle}>
          <Cols set="auto auto auto" gap={32}>
            <Logo />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <TopMenu />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <RoundTimeLine />
            </div>
          </Cols>
          {/*<Box p={2} />
         <TopMenu /> */}
        </header>
      </Breakpoint>
      <Breakpoint customQuery={query.desktopUltraUp}>
        <header style={headerDesktopStyle}>
          <Cols set="auto auto auto" gap={32}>
            <Logo />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <TopMenu />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <RoundTimeLine />
            </div>
          </Cols>
        </header>
      </Breakpoint>
    </>
  )
}
