import { Skeleton } from '@mui/material'
import { CardGrid } from 'Components/game/cardGrid'
import { GamePredictionCard } from 'Components/game/gamePredictionCard'
import { PreviewDrawer } from 'Components/interactive/previewDrawer'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { HLine } from 'Components/visual/HLine'
import { SubtleText } from 'Components/visual/SubtleText'
import { isAfter } from 'date-fns'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useGames } from 'store/game/gameContext'
import { useRounds } from 'store/game/roundContext'
import { useAuth } from 'store/user/authContext'
import { buildTodayZero } from 'util/date'
import {
  SCORE_LIKELY_THRESHOLD,
  hasPredictionForGame,
  isGameLocked,
  isGameReleased,
} from 'util/gameLogic'
import { sortByReleasedAndName } from 'util/sort'

export const UserPredictList = () => {
  const { isLoadingAvailableGames, availableGames } = useGames()
  const { selectedRound, isGameReleasedInRound } = useRounds()
  const { user } = useAuth()

  const gamesPossibleToPredict = useMemo(() => {
    const possibleGames = availableGames
      .filter(
        (game) =>
          game.profilePoints >= SCORE_LIKELY_THRESHOLD &&
          !isGameLocked(game) &&
          !isGameReleased(game) &&
          isGameReleasedInRound(game, {
            startDate: isAfter(selectedRound.startDate, buildTodayZero())
              ? buildTodayZero()
              : selectedRound.startDate,
            endDate: selectedRound.endDate,
          })
      )
      .sort((a, b) => sortByReleasedAndName(a, b))

    return possibleGames
  }, [availableGames, selectedRound, isGameReleasedInRound])

  const gamesToPredict = useMemo(() => {
    const gamesToPredict = gamesPossibleToPredict.filter(
      (game) => !hasPredictionForGame(user, game)
    )

    return gamesToPredict
  }, [gamesPossibleToPredict, user])

  return (
    <ChildSpacerVAll>
      <PreviewDrawer
        anchor="left"
        previewItemLimit={1}
        ContentComponent={PredictGameGrid}
        isLoading={isLoadingAvailableGames}
        gamesToPredict={gamesToPredict}
        gamesPossibleToPredict={gamesPossibleToPredict}
        user={user}
      />
    </ChildSpacerVAll>
  )
}

function PredictGameGrid({
  user,
  limit,
  isLoading,
  gamesToPredict,
  gamesPossibleToPredict,
}) {
  if (isLoading) {
    return (
      <>
        <HLine label="Predict the score of this suggested game" />
        <Skeleton variant="rectangular" height={200} sx={{ borderRadius: 2 }} />
      </>
    )
  }

  if (gamesPossibleToPredict.length <= 0) {
    return (
      <>
        <HLine label="Predict the review score" />
        <div>
          There are no suggested games left to predict. There may be more to
          predict under <Link to="/games">Games</Link> if you want to catch them
          all.
        </div>
      </>
    )
  }

  if (gamesToPredict.length <= 0) {
    return (
      <>
        <HLine label="Predict the review score" />
        <div>
          Way to go! You have predicted review scores for every suggested game.
          There may be more to predict under <Link to="/games">Games</Link> if
          you want to catch them all.
        </div>
      </>
    )
  }

  return (
    <>
      <HLine label="Predict the score of this suggested game" />
      <CardGrid
        limit={limit}
        list={gamesToPredict}
        isLoading={isLoading}
        Component={GamePredictionCard}
        readonly={true}
        emptyText="Way to go! You have predicted review scores for every suggested game. There may be more to predict under Games if you want to catch them all."
      />
      {user.isAuthenticated && gamesToPredict.length > 0 && (
        <SubtleText>
          Predict the review score for {gamesToPredict.length} more{' '}
          {gamesToPredict.length > 1 ? 'games' : 'game'} to have the maximum
          chance to win.
        </SubtleText>
      )}
    </>
  )
}
